@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@font-face {
    font-family: 'Geometria';
    src: url('../fonts/Geometria-ExtraBold.eot');
    src: url('../fonts/Geometria-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Geometria-ExtraBold.woff2') format('woff2'),
        url('../fonts/Geometria-ExtraBold.woff') format('woff'),
        url('../fonts/Geometria-ExtraBold.ttf') format('truetype'),
        url('../fonts/Geometria-ExtraBold.svg#Geometria-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Geometria';
    src: url('../fonts/Geometria.eot');
    src: url('../fonts/Geometria.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Geometria.woff2') format('woff2'),
        url('../fonts/Geometria.woff') format('woff'),
        url('../fonts/Geometria.ttf') format('truetype'),
        url('../fonts/Geometria.svg#Geometria') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Geometria';
    src: url('../fonts/Geometria-Bold.eot');
    src: url('../fonts/Geometria-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Geometria-Bold.woff2') format('woff2'),
        url('../fonts/Geometria-Bold.woff') format('woff'),
        url('../fonts/Geometria-Bold.ttf') format('truetype'),
        url('../fonts/Geometria-Bold.svg#Geometria-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Geometria';
    src: url('../fonts/Geometria-ExtraLight.eot');
    src: url('../fonts/Geometria-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Geometria-ExtraLight.woff2') format('woff2'),
        url('../fonts/Geometria-ExtraLight.woff') format('woff'),
        url('../fonts/Geometria-ExtraLight.ttf') format('truetype'),
        url('../fonts/Geometria-ExtraLight.svg#Geometria-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Geometria';
    src: url('../fonts/Geometria-Heavy.eot');
    src: url('../fonts/Geometria-Heavy.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Geometria-Heavy.woff2') format('woff2'),
        url('../fonts/Geometria-Heavy.woff') format('woff'),
        url('../fonts/Geometria-Heavy.ttf') format('truetype'),
        url('../fonts/Geometria-Heavy.svg#Geometria-Heavy') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Geometria';
    src: url('../fonts/Geometria-Light.eot');
    src: url('../fonts/Geometria-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Geometria-Light.woff2') format('woff2'),
        url('../fonts/Geometria-Light.woff') format('woff'),
        url('../fonts/Geometria-Light.ttf') format('truetype'),
        url('../fonts/Geometria-Light.svg#Geometria-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Geometria';
    src: url('../fonts/Geometria-Medium.eot');
    src: url('../fonts/Geometria-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Geometria-Medium.woff2') format('woff2'),
        url('../fonts/Geometria-Medium.woff') format('woff'),
        url('../fonts/Geometria-Medium.ttf') format('truetype'),
        url('../fonts/Geometria-Medium.svg#Geometria-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Geometria';
    src: url('../fonts/Geometria-Thin.eot');
    src: url('../fonts/Geometria-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Geometria-Thin.woff2') format('woff2'),
        url('../fonts/Geometria-Thin.woff') format('woff'),
        url('../fonts/Geometria-Thin.ttf') format('truetype'),
        url('../fonts/Geometria-Thin.svg#Geometria-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

html{font-size:64% !important;height:100%;font-family:'Geometria';-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;}
body{font-family:'Geometria';font-weight:400;font-style:normal;box-sizing:border-box;position:relative;font-size:1.4rem;line-height:26px;color:#115586;-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;}
a, a:hover, a:focus, a:active{text-decoration:none;outline:0;}
input, input:focus, input:hover, input:active{outline:0;box-shadow:none;}
ul{margin:0 auto;padding:0;list-style:none;}
button, button:hover, button:active, button:focus{box-shadow:none;outline:0;border:0;}
.btn{font-size:1.2rem;}

.styled-checkbox{position:absolute;opacity:0;}
.styled-checkbox + label{position:relative;cursor:pointer;padding:0;font-size:1.2rem;color:#C6C6C6}

.signinWrap form ul li .styled-checkbox + label {
    font-size: 1.4rem;
    color: #512378;
}
.styled-checkbox + label:before{content: "";
    margin-right: 5px;
    display: inline-block;
    vertical-align: text-top;
    width: 15px;
    height: 15px;
    border: 1px solid #512378;
    border-radius: 4px;
    margin-top: 1px;}
.styled-checkbox:hover + label:before{background:#512378;}
.styled-checkbox:focus + label:before{box-shadow:none;}
.styled-checkbox:checked + label:before{background:#512378;}
li.bottomFields.full .leftWrap.form-group .form-check-input {
    position: absolute;
    margin-top: .3rem;
    margin-left: 0rem;
    width: 16px;
    height: 16px;
    z-index: 9;
    cursor: pointer;
}
.styled-checkbox:disabled + label{color:#b8b8b8;cursor:auto;}
.styled-checkbox:disabled + label:before{box-shadow:none;background:#ddd;}
.styled-checkbox:checked + label:after{content:"";position:absolute;left:2.5px;top:12px;background:#ffffff;width:2px;height:2px;box-shadow:2px 0 0 #ffffff, 4px 0 0 #ffffff, 4px -2px 0 #ffffff, 4px -4px 0 #ffffff, 4px -6px 0 #ffffff, 4px -8px 0 #ffffff;transform:rotate(45deg);}

[type="radio"]:checked,
[type="radio"]:not(:checked){position:absolute;left:-9999px;}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label{position:relative;padding-left:28px;cursor:pointer;line-height:20px;display:inline-block;
color:#666666;}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before{content:'';position:absolute;left:0;top:0;width:14px;height:14px;border:1px solid #EDEDEF;border-radius:100%;background:transparent;}
[type="radio"]:checked + label:before{border:1px solid #5DB8B2;}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after{content:'';width:8px;height:8px;background:#5DB8B2;position:absolute;top:3px;left:3px;border-radius:100%;-webkit-transition:all 0.2s ease;transition:all 0.2s ease;}
[type="radio"]:not(:checked) + label:after{opacity:0;-webkit-transform:scale(0);transform:scale(0);}
[type="radio"]:checked + label:after{opacity:1;-webkit-transform:scale(1);transform:scale(1);}



.signinWrap{width:100%;background-image:url(../img/bg-img-2.jpg);background-repeat:round;background-size:100%;height:100vh;display:flex;align-items:center;justify-content:center;flex-flow:row wrap;padding:40px 0;}
.signinWrap .formContent{text-align:center;}
.signinWrap .formContent .logoWrap h2{color:#767676;font-size:1.3rem;text-transform:uppercase;margin:0;}
.signinWrap .formContent .formData{max-width:400px;margin:0 auto;background-color:rgba(255,255,255,0.9);box-shadow:0px 3px 60px #7b7b7b29;border-radius:4px;padding:25px 45px;margin:15px auto 0;}
.signinWrap .topData h2{color:#727272;text-align:left;margin-top:25px;}
.signinWrap .topData p{color:#727272;text-align:left;margin:0;line-height:20px;}
.signinWrap .topData p a{color:#512378;}
.signinWrap .fromWrap{text-align:left;padding:20px 0 0;}
.signinWrap form ul li{margin:0 0 15px;}
.signinWrap form ul li label{color:#6E6E6E;margin:0 auto;outline:0;}
.signinWrap form ul li .inputField, .contact-number .inputField{border:1px solid #CACACA;border-radius:4px;display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;padding:2px 8px;height:40px;width:100%;background: #fff;}
.signinWrap form ul li .inputField.countrycode ,.inputField.countrycode{width:74px;float:left;margin-right:6px;}

.signinWrap form ul li .inputField.phoneno,
.inputField.phoneno{width:calc(100% - 80px);display:inline-block;}

.signinWrap form ul li .inputField input, .contact-number .inputField input{border:0;outline:0;background-color:#fff!important;height:100%;padding:0;border-radius:4px;width:calc(100% - 26px);}
.signinWrap form ul li .inputField img, .contact-number .inputField img{width:22px;}
.signinWrap form ul li.bottomFields{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;}
.signinWrap form ul li .leftWrap,
.signinWrap form ul li .rightWrap{width:50%;}
.signinWrap form ul li .rightWrap{text-align:right;}
.signinWrap form ul li .rightWrap a{color:#512378;}
.signinWrap form ul li:last-child{margin:0;}
.ctaWrap{text-align:center;}
/* .ctaWrap a{color:#FFFFFF;background-color:#5DB8B2;border-radius:4px;width:100%;display:inline-block;padding:6px 10px 5px;font-size:1.6rem;} */
/* .signinWrap form ul li.agreeCheck label span{color:#512378;font-weight:500;} */
.signinWrap form ul li.agreeCheck label {
    margin-left: -8px;
}
.ctaWrap a{color:#FFFFFF;font-family:'Roboto', sans-serif;background-color:#5DB8B2;border-radius:4px;width:100%;display:inline-block;padding:6px 10px 5px;font-size:1.6rem;}
.signinWrap form ul li.agreeCheck label span{color:#512378;font-weight:500;cursor:pointer;}
.signinWrap form ul{display:flex;flex-flow:row wrap;justify-content:space-between;}
.signinWrap .custom-radio {width: auto;display: inline-block !important;padding-left:10px;}
.signinWrap form ul li.full{width:100%;}
.signinWrap form ul li.full.marbtm{margin:0 0 10px;}
.signinWrap form ul li.half{width:48%;}
.signinWrap form ul li h3{color:#313131;margin:0;font-size:1.5rem;}
.signinWrap.creataccoutWrap{background-attachment:fixed;height:auto;}
.signinWrap .logoWrap img {
    width: 250px;
}
.copyright {
    text-align: center;
    letter-spacing: 0px;
    color: #9D9D9D;
    opacity: 1;
    margin: 0px;
    padding-top: 20px;
}

.btnSign button.btn-transperent {
    background: transparent;
    color: #512378;
    border: 1px solid #512378;
    width: 40%;
    padding: 9px;
    margin-right: 5%;
}
.btnSign button.btn-transperent:hover{
    background:#512378;
    color: #fff;
}
.btnSign button.btn-send {
    width: 55%;
    font-size: 1.4rem;
}

.headerWrap{width:100%;margin:0 auto;padding:0;}
.navbar{padding:6px 0;border-bottom:1px solid #EDEDEF;}
.navbar .container{justify-content:space-between;}
.navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;

}
.navbar-brand img.imgLogo1{width:135px;}
.navbar-brand.logoWrap1{display:inline-block;position:relative;margin:0 90px 0 0;}
.navbar-brand.logoWrap1:after{content:"";background-color:#EDEDEF;width:1px;height:20px;position:absolute;right:-40px;top:50%;transform:translateY(-50%);}
.navbar-brand img.imgLogo2 {
    width: auto;
    max-height: 40px;
}
.navbar .nav{display:flex;flex-flow:row wrap;justify-content:flex-end;text-align:right;width:calc(100% - 350px);margin-right: 0px;}
.navbar .nav li{margin:0 0 0 25px}
.navbar .nav li a{color:#838383;font-size:1.4rem;}
.navbar .nav li a.dropdown-toggle{display:flex;align-items:center;justify-content:space-between;flex-flow:row wrap;}
.navbar .nav li a.dropdown-toggle::after{content:none;}
.navbar .nav li a.dropdown-toggle img{width:32px;height:32px;border-radius:100%;}
.navbar .nav li a.dropdown-toggle p{font-size:1.4rem;line-height:19px;margin:0 8px;}
.navbar .nav li a.dropdown-toggle p span{color:#5DB8B2;display:block;font-size:1.1rem;line-height:10px;text-align:left;}
.navbar .nav li a.dropdown-toggle span{color:#A2A2A2;font-size:1rem;}
.navbar .nav li.active a svg path{fill:#5DB8B2;}
.navbar .nav li a svg path{fill:#A2A2A2;}
.navbar .nav li a .aa,
.navbar .nav li a .bb{fill:none;stroke:#838383;stroke-width:1.5px;}
.navbar .nav li a .bb{stroke-linecap:round;}
.navbar .nav li.active a .aa,
.navbar .nav li.active a .bb{stroke:#ffffff;}
.navbar .nav li.active a{color:#5DB8B2;}
.navbar .nav .dropdown-menu{top:40px;box-shadow:0px 3px 6px #00000029;min-width:150px;border:0;margin:0 auto;}
.navbar .nav .dropdown-menu li{border-bottom:1px solid #EBEBEC;margin:0 auto;padding:8px 15px;}
.navbar .nav .dropdown-menu li:last-child{border:0;}
.navbar .nav .dropdown-menu li a{text-transform:capitalize;color:#606060;display:block;}
.navbar .nav .dropdown-menu li.premiumUser a{font-size:1.2rem;line-height:24px;}
.navbar .nav .dropdown-menu li.premiumUser a span{background-color:#F3A233;border-radius:4px;color:#ffffff;padding:4px 12px;}
.topContent{padding:20px 0;width:100%;}
.topContent ul{display:flex;flex-flow:row wrap;justify-content:flex-start;align-items:center;}
.topContent ul li{margin:0 10px 0 0;}
.topContent ul li a img{width:30px;}
.topContent ul li .selectWrap{position:relative;margin-right:15px;}
.topContent ul li .selectWrap:after{content:"\f11a";font-family:Flaticon;color:#A2A2A2;font-size:0.9rem;}
.topContent ul li .selectWrap select{-webkit-appearance:none;appearance:none;border:0;outline:0;font-weight:500;width:75px;}
.topContent ul li.btnPremium a{background-color:#F3A233;color:#ffffff;font-size:1.2rem;border-radius:4px;padding:4px 10px;}
.middleContent{width:100%;margin:0 auto;}
.middleContent ul.nav-tabs{width:100%;margin:0 auto;display:flex;flex-flow:row wrap;justify-content:space-between;border:0;}
.middleContent ul.nav-tabs li{width:24%;margin:0;height:80px; cursor: pointer;}
.nav-tabs .nav-link{border:0;border:1px solid #EBEBEC!important;border-radius:8px;}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active,
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover{outline:0;border:1px solid #5DB8B2!important;}
.middleContent ul.nav-tabs li a{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;height:100%;padding:15px;}
.middleContent ul.nav-tabs li .leftdata{position:relative;padding:0 0 0 26px;}
.middleContent ul.nav-tabs li .leftdata:before{content:"";background-repeat:no-repeat;width:18px;height:18px;background-size:100%;position:absolute;left:0;top:50%;transform:translateY(-50%);}
.middleContent ul.nav-tabs li .leftdata.icoWrap-1:before{background-image:url(../img/ico-dots-3.png);}
.middleContent ul.nav-tabs li a.active .leftdata.icoWrap-1:before{background-image:url(../img/ico-dots-1.png);}
.middleContent ul.nav-tabs li .leftdata.icoWrap-2:before{background-image:url(../img/ico-gallery-1.png);width:22px;height:22px;}
.middleContent ul.nav-tabs li a.active .leftdata.icoWrap-2:before{background-image:url(../img/ico-gallery-2.png);}
.middleContent ul.nav-tabs li .leftdata.icoWrap-3:before{background-image:url(../img/ico-user-1.png);width:17px;height:22px;}
.middleContent ul.nav-tabs li a.active .leftdata.icoWrap-3:before{background-image:url(../img/ico-user-4.png);}
.middleContent ul.nav-tabs li .leftdata.icoWrap-4:before{background-image:url(../img/ico-bars-1.png);}
.middleContent ul.nav-tabs li a.active .leftdata.icoWrap-4:before{background-image:url(../img/ico-bars-3.png);}
.middleContent ul.nav-tabs li .leftdata p{margin:0;color:#606060;text-transform:capitalize;}
.middleContent ul.nav-tabs li .rightData h2{color:#606060;margin:0;font-size:2.2rem;font-weight:500;}
.tab-content .topData{width:100%;margin:0 auto;padding:5px 0 20px 0;display:flex;flex-flow:row wrap;justify-content:space-between;}
.leftPanel h2{color:#4E4E4E;margin:0;font-size: 1.8rem;}
.rightPanel{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;}
.rightPanel .searchWrap,
.memberPopup .searchWrap{margin:0 10px 0 0;border-radius:8px;background-color:#fafafa;display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;padding:3px 15px;width:calc(100% - 200px);height:38px;border:1px solid transparent;}
.rightPanel .searchWrap:hover,
.rightPanel .searchWrap:focus,
.memberPopup .searchWrap:hover,
.memberPopup .searchWrap:focus{border:1px solid #5DB8B2;}
.rightPanel .searchWrap input,
.memberPopup .searchWrap input{width:calc(100% - 25px);background-color:transparent;border:0;outline:0;box-shadow:none;height:100%;}
.rightPanel .searchWrap input::-webkit-input-placeholder{color:#BCBCBC;}
.rightPanel .searchWrap input::-moz-placeholder{color:#BCBCBC;}
.rightPanel .searchWrap input:-ms-input-placeholder{color:#BCBCBC;}
.rightPanel .searchWrap input:-moz-placeholder{color:#BCBCBC;}
.rightPanel .searchWrap img{width:16px;}
.rightPanel button.btn{border-radius:4px;background-color:#5DB8B2;color:#FFFFFF;border:0;outline:0;box-shadow:none;height:38px;padding:2px 23px;text-transform:capitalize;}
.rightPanel button.btn img{width:16px;margin:0 6px 0 0;}
ul.collectionList{}
ul.collectionList .item-contentwrap{
    border: 1px solid #EDEDEF;
    border-radius: 10px;
    padding: 10px;
    background: #fff;
    border-left: 7px solid #5DB8B2;
    margin-bottom: 15px;}
ul.collectionList li .titleWrap{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:flex-start;}
ul.collectionList li .titleWrap .leftData{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center; cursor: pointer;width:calc(100% - 24px);}
ul.collectionList li .titleWrap .leftData .imgWrap{background-color:rgba(93, 184, 178, 0.08);border-radius:5px;margin:0 10px 0 0;  width:40px;height:40px;text-align:center;line-height:40px;}
ul.collectionList li .titleWrap .leftData .imgWrap img{text-align:center;margin:0 auto;display:inline-block;width:24px;}
ul.collectionList li .titleWrap .leftData h2{color:#585858;font-size:1.8rem;margin:0;width:calc(100% - 50px);}
ul.collectionList li .titleWrap .rightData{width:24px;}
ul.collectionList li .titleWrap .rightData a img, .titleWrap .rightData a img{width:24px;}
ul.collectionList li .titleWrap .rightData .dropdown-menu.show, .titleWrap .rightData .dropdown-menu.show{display:block;left:auto !important;right:0px;box-shadow:0px 3px 6px #00000029;border:0;border-radius:0;
    /* padding:0; */
    padding: 0px 12px 0px 0px;
    margin:0;}
ul.collectionList li .titleWrap .rightData .dropdown-menu a, .titleWrap .rightData .dropdown-menu a{color:#606060;border-bottom:1px solid #f5f5f5;padding:5px 10px;min-width:120px;font-size:1.2rem;}
.dropdown-item.active,
.dropdown-item:active{background-color:transparent;}
ul.collectionList li .titleWrap .rightData .dropdown-menu a img, .titleWrap .rightData .dropdown-menu a img{width:20px;margin:0 8px 0 0;}
ul.collectionList li .dataWrap{padding:10px 0 15px;height: 66px;}
ul.collectionList li .dataWrap p{color:#606060;line-height:22px;margin:0;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;overflow:hidden;}
ul.collectionList .collectionInfo{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;}
ul.collectionList .collectionInfo ul.privacyList li{width:auto;padding:0;border:0;border-radius:0;display:flex;align-items:center;flex-flow:row wrap;margin:0 auto 4px;}
ul.collectionList .collectionInfo ul.privacyList li img{width:20px;margin:0 8px 0 0;}
ul.collectionList .collectionInfo ul.privacyList li p{color:#606060;margin:0;}
ul.collectionList .collectionInfo .usersListing,
.collectionListpageWrap .sidePanel ul li .usersListing{display:flex;flex-flow:row wrap;justify-content:center;align-items: center;}
ul.collectionList .collectionInfo .usersListing li,
.collectionListpageWrap .sidePanel ul li .usersListing li{position:relative;z-index:0;margin-left:-10px;border:none;-webkit-transition:all .3s ease;transition:all .3s ease;width:auto;padding:0;}
ul.collectionList .collectionInfo .usersListing li:hover,
.collectionListpageWrap .sidePanel ul li .usersListing li:hover{-webkit-transition:all .3s ease;transition:all .3s ease;z-index:1;}
ul.collectionList .collectionInfo .usersListing li a,
.collectionListpageWrap .sidePanel ul li .usersListing li a{width:30px;height:30px;display:inline-block;border-radius:100%;overflow:hidden;border:2px solid #fff}
ul.collectionList .collectionInfo .usersListing li a img,
.collectionListpageWrap .sidePanel ul li .usersListing li a img{width:100%;height:100%;object-fit:cover;}
ul.collectionList .collectionInfo .usersListing li a.Countlabel,
.collectionListpageWrap .sidePanel ul li .usersListing li a.Countlabel{background-color:#E6E6E6;text-align:center;}
ul.collectionList .collectionInfo .usersListing li a.Countlabel span,
.collectionListpageWrap .sidePanel ul li .usersListing li a.Countlabel span{color:#1C1C1C;font-weight:600;font-size:1.2rem;text-align:center;display:inline-block;}
.collectionListpageWrap .collection-sidebar {
    background: #fff;
    min-height: 80vh;
}


.gopremiumWrap{background-color:#ffffff;text-align:center;box-shadow:0px 0px 11px #27272729;border-radius:8px;max-width:230px;margin:15px 0 30px;position:relative;padding:20px 10px 12px;float:right;clear:both;right:-60px;}
.gopremiumWrap .cross-icon {
    position: absolute;
    right: 8px;
    top: 8px;
}
.gopremiumWrap p{color:#606060;line-height:22px;}
.gopremiumWrap p a{color:#FFB92B;}
.gopremiumWrap a.btnlearnMore{border-radius:4px;background-color:#5DB8B2;color:#ffffff;padding:4px 10px;display:inline-block;font-size:1.2rem;}
/* .gopremiumWrap .btn{padding:0;border:0;box-shadow:none;outline:0;background-color:transparent;position:absolute;right:-8px;top:-8px;} */
.gopremiumWrap .btn img{width:20px;}
table{width:100%;background: #fff;}
table thead tr{background-color:#FAFAFA;}
table tr th{color:#707070;font-size:1.4rem;font-weight:500;}
table tr td,
table tr th{padding:10px 15px;}
table tr td:first-child{color:#606060;    font-weight: 500}
table tr td.colorGreen{color:#5DB8B2; cursor: pointer;}
table tr td.waiting span,
table tr td.colorRed span,
table tr td.approved span,
table tr td.colorIndigo span,
table tr td.colorBgGreen span,
table tr td.bgclrOrange span{background-color:#D33E6D;border-radius:4px;padding:3px 10px;color:#ffffff;font-size:12px;}
table tr td.approved span{background-color:#5DB8B2;}
table tr td.colorIndigo span{background-color:#512378;}
table tr td.colorBgGreen span{background-color:#5DB8B2;}
table tr td.bgclrOrange span{background-color:#F3A233;}
table tr td{color:#707070;border-bottom:1px solid #EDEDEF;}
table tr:last-child td{border:0;}
table tr th img{width:20px;}
table tr th .btnSort{padding:0;background:transparent;margin:0;border:0;outline:0;box-shadow:none;}
table tbody tr:hover{background-color:rgba(93, 184, 178, 0.09);}
table tr td .imgSquare{width:42px;height:42px;object-fit:cover;margin:0 6px 0 0;}
table tr td .imgRound{width:30px;height:30px;border-radius:100%;object-fit:cover;margin:0 6px 0 0;}
table tr td .btnAction{padding:0;background:transparent;margin:0;border:0;outline:0;box-shadow:none;margin:0 0 0 6px;}
table tr td .btn:focus,
table tr td .btn:hover{outline:0;box-shadow:none;}
table tr td .btnAction img{width:24px;}
table tr td .btnAction img.icoDelete,
table tr td .btnAction img.icoSetting{width:22px;}
table tr td .btnAction img.icoExport{width:20px;}
table tr td .btnAction img.icomulticollection{width:18px}
table tr td h3{font-size:1.4rem;font-weight:500;margin:0;color:#606060;}
table tr td p{margin:0;color:#606060;font-weight:300;font-size:1.2rem;}
table tr td .btnSave{background-color:#5DB8B2;color:#ffffff;border:0;outline:0;box-shadow:none;padding:8px 20px;}
table tr td .selectWtap select{outline:0;box-shadow:none;padding:0px 10px;width:100%;border:0;appearance:none;-webkit-appearance:none;background-color:transparent;}
table tr td .selectWtap{border:1px solid #EDEDEF;border-radius:4px;width:100%;background-color:#F8F8F8;position:relative;}
table tr td .selectWtap:after{content:"\f11a";font-family:Flaticon;color:#A2A2A2;font-size:0.9rem;position:absolute;right:10px;top:54%;transform:translateY(-50%);}
table tr td.noData{text-align:center;color:#707070;padding-bottom:40px;}
table tr.noHover:hover{background-color:transparent;}

.companyPageWrap .topData{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;padding:30px 0 15px;}
.companyPageWrap .topData .leftPanel h2 span{font-weight:200;}
.companyPageWrap .topData .rightPanel .searchWrap{margin:0;}

.managePageWrap{width:100%;margin:0 auto;padding:0;}
.managePageWrap .pageTitle{display:flex;flex-flow:row wrap;justify-content:space-between;padding:25px 0;}
.managePageWrap .pageTitle .title h2{color:#4E4E4E;margin:0;}
.managePageWrap .pageTitle .ctaWrap .btn,
.managePageWrap .pageTitle .ctaWrap a.btn{background-color:#5DB8B2;color:#ffffff;border-radius:4px;border:0;outline:0;box-shadow:none;    padding: 8px 20px;
    margin: 0 0 0 6px;
    width: auto;
    font-size: 14px;}
.managePageWrap .pageTitle .ctaWrap .btn img{width:18px;margin:0 5px 0 0;}
.managePageWrap .pageTitle .ctaWrap .btn img.imgBar{transform:rotate(90deg);}

.managePageWrap .leftbarWrap ul>li>a{color:#606060;font-size:1.6rem;font-weight:500;padding:8px 15px;display:inline-block;text-transform:capitalize;border:1px solid transparent;width:100%;}
.managePageWrap .leftbarWrap ul>li.active a{border:1px solid #5DB8B2;}

.managePageWrap .leftbarWrap ul li ul li {
    background-color: #ffffff;
    border: 1px solid #EDEDEF;
    margin-bottom: 10px;
}
.managePageWrap .leftbarWrap ul li ul li a{font-weight:400;}
.managePageWrap .leftbarWrap ul>li.active ul li a{border:0;}
.managePageWrap .leftbarWrap ul>li.active ul li.current a{color:#5DB8B2;border:0;}
.managePageWrap .sectionTitle h2{color:#24243A;margin:0;background-color:#F9F9F9;font-size:1.6rem;padding:12px 20px;}
.managePageWrap  ul.prescriptionList{display:flex;flex-flow:row wrap;justify-content:space-between;}
.managePageWrap  ul.prescriptionList li{border:1px solid #EDEDEF;border-radius:6px;width:49%;padding:20px;margin:0 0 15px;}
.managePageWrap  ul.prescriptionList li h2{color:#585858;margin:0;text-transform:capitalize;}
.managePageWrap  ul.prescriptionList li p{margin:12px auto;color:#606060;line-height:22px;}
.managePageWrap  ul.prescriptionList .bottomData{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:flex-end;}
.managePageWrap  ul.prescriptionList .bottomData .leftPanel ul li{border:0;padding:0 0 0 25px;border-radius:0;width:100%;margin:0 0 5px;position:relative;color:#606060;}
.managePageWrap  ul.prescriptionList .bottomData .leftPanel ul li:before{content:"";position:absolute;background-image:url(../img/ico-check.png);background-repeat:no-repeat;width:12px;height:12px;position:absolute;background-size:100%;left:0;top:50%;transform:translateY(-50%);}
.managePageWrap  ul.prescriptionList .bottomData .rightPanel .btn{font-size:1.3rem;padding:8px 25px;height:auto;}
.managePageWrap  ul.prescriptionList .bottomData .rightPanel .btnRed{background-color:#D33E6D;}
.pl20px{padding:0 0 0 20px;}
form ul li{margin:0 0 10px;}
form ul li .formField{width:100%;margin:0 auto;display:flex;flex-flow:row wrap;justify-content:space-between;}
form ul li .formField.bgclrGray input{background-color:#F7F7F7;}
form ul li .formField.editField{position:relative;}
form ul li .formField.editField:after, .deleteinput{content:"";background-image:url(../img/ico-close.png);background-repeat:no-repeat;width:18px;height:18px;background-size:100%;position: absolute;
    transform: translateY(-50%);
    right: -10px;
    padding-top: 10px;
    margin-top: 15px;
    cursor: pointer;}
form ul li .formField label{color:#606060;font-size:1.4rem;font-weight:400;position:relative;width:190px;}
form ul li .formField.editField label{border:1px solid #EDEDEF;border-radius:4px;padding:0px 5px;}
form ul li .formField label span{color:#D63930;}
form ul li .formField input,
form ul li .formField textarea{width:calc(100% - 200px);border:1px solid #EDEDEF;border-radius:4px;}
form ul li .formField input,
form ul li .formField textarea,
form ul li .selectWtap select{outline:0;box-shadow:none;padding:0px 10px;}
form ul li .selectWtap{border:1px solid #EDEDEF;border-radius:4px;width:calc(100% - 200px);background-color:#FFF;position:relative;}
form ul li .selectWtap select{width:100%;border:0;appearance:none;-webkit-appearance:none;background-color:transparent;}
form ul li .selectWtap:after{content:"\f11a";font-family:Flaticon;color:#A2A2A2;font-size:0.9rem;position:absolute;right:10px;top:54%;transform:translateY(-50%);}
.upload-btn-wrapper{position:relative;overflow:hidden;display:inline-block;border:1px solid #EDEDEF;border-radius:4px;width:calc(100% - 200px);padding:0 0 2px 4px;}
.upload-btn-wrapper .btn{border:0;color:#ffffff;background-color:#5DB8B2;padding:2px 10px;border-radius:4px;
font-size:1.2rem;font-weight:400;text-transform:capitalize;}
.upload-btn-wrapper input[type=file]{font-size:1.4rem;position:absolute;left:0;top:0;opacity:0;cursor: pointer;}
form ul li .checkboxWrap{width:calc(100% - 200px);}
form ul li .checkboxWrap label{width:auto;margin:0 15px 0 0;}
form ul li .checkboxWrap input{width:auto;}

.passwordfield .inputField.form-group {
    width: calc(100% - 200px);
    border: 1px solid #EDEDEF;
}

.passwordfield .formField input {
    width: calc(100% - 30px);
    border: 0px;
}

.formPopup .modal-dialog{max-width:620px;margin:0 auto;display:flex;align-items:center;justify-content:center;flex-direction:column;height:80%;margin:60px auto;}
.formPopup .modal-header{display:flex;align-items:center;justify-content:space-between;flex-flow:row wrap;}
.formPopup .modal-header h5{color: #4E4E4E;
    font-size: 1.6rem;
    font-weight: 500;
    padding-left: 20px;}
.formPopup .modal-header h5 img{width:24px;margin:0 10px 0 0;cursor: pointer;}
.formPopup .modal-header .btn,
.assistantPopup .ctaAdd .btn, .btn-x{    color: #A7A7A7;
    border: 0;
    outline: 0;
    box-shadow: none;
    padding: 0px 15px;
    background: transparent;
    font-size: 20px;
cursor: pointer;
height: auto;
line-height: 1;}
.formPopup .modal-header .btn.btnClose{background-color:transparent;padding:0;outline:0;box-shadow:none;border:0;color:#A7A7A7;}
.formPopup .modal-body{padding:30px;}
.formPopup form ul li.editImage{display:flex;flex-flow:row wrap;justify-content:flex-start;align-items:center;padding:0 0 15px;}
.deletePopup .modal-dialog{margin:0 auto;display:flex;align-items:center;justify-content:center;flex-direction:column;height:100%;}
.deletePopup .modal-body{padding:20px;}
.deletePopup .title h2{margin:0;color:#2C2C2C;font-weight:600;font-size:1.8rem;border-bottom:1px solid #EBEBEC;padding:0 0 10px;margin:0 0 10px;}
.deletePopup .dataWrap p{color:#4B4B4B;margin:0;line-height:22px;}
.deletePopup .dataWrap .ctaWrap,
.assistantPopup .ctaWrap{padding:15px 0 0;text-align:right;}
.deletePopup .dataWrap .ctaWrap .btn,
.assistantPopup .ctaWrap .btn,
.lookupConstituentPopup .btnsWrap .btn,
.addConstituentPopup .ctaWrap .btn{padding:8px 15px;outline:0;box-shadow:none;margin:0 0 0 8px;border-radius:4px;text-transform:capitalize;}
.deletePopup .dataWrap .ctaWrap .btn.btnCancel,
.assistantPopup .ctaWrap .btn.btnclose,
.addConstituentPopup .ctaWrap .btn.btnclose,
.lookupConstituentPopup .ctaWrap .btn.btnclose{border:1px solid #5DB8B2;color:#5DB8B2;background-color:transparent;}
.deletePopup .dataWrap .ctaWrap .btn.btnConfirm,
.assistantPopup .ctaWrap .btn.btnSave,
.addConstituentPopup .ctaWrap .btn.btnSave,
.lookupConstituentPopup .btnsWrap .btnNew{background-color:#5DB8B2;color:#ffffff;border:0;}

.companysettingPopup .title h2{color:#4E4E4E;font-size:1.6rem;border-bottom:1px solid #EBEBEC;padding:0 0 10px;margin:0 0 30px;}
.companysettingPopup .modal-body{padding:20px 30px;}
.companysettingPopup form ul li.btnWrap{text-align:right;}
.companysettingPopup form ul li.btnWrap .btn, .btn-primary{color:#ffffff;background-color:#5DB8B2;border:0;outline:0;box-shadow:none;padding:8px 15px;    border-radius: 4px;
    line-height: 1.5;}
.btn-primary:hover{color:#ffffff;background-color:#5DB8B2;border:1px solid #5DB8B2;outline:0;box-shadow:none;padding:8px 15px;}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #5DB8B2;
    border-color: #5DB8B2;
}
.companysettingPopup form ul li.editImage .imgWrap{width:197px;float: left;}
/* .companysettingPopup form ul li.editImage .imgWrap img{width:120px;border:1px solid #EBEBEC;padding:10px;} */
.companysettingPopup form ul li.editImage .imgWrap .profile-img{
    width: 70px;
    height: 70px;
    border: none !important;
    padding: 10px;
    border-radius: 100%;
}
.companysettingPopup form ul li.editImage .imgWrap .company-img{
    max-width: 70px;
    max-height: 40px;
}
.companysettingPopup form ul li.editImage .upload-btn-wrapper{border:0;width:auto;}
.companysettingPopup form ul li.editImage .upload-btn-wrapper .btn{padding:8px 12px;margin:0 10px 0 0;}
.companysettingPopup form ul li.editImage .btnDelete{border:1px solid #5DB8B2;padding:8px 20px;color:#5DB8B2;background-color:transparent;outline:0;box-shadow:none;}
.companysettingPopup form ul li.editImage .imgWrap img.userImg{border-radius:100%;padding:0;border:0;width:80px;}

.collectionListpageWrap .topContentWrap{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;padding:25px 0 15px;}
.collectionListpageWrap .topContentWrap .leftPanel{display:flex;flex-flow:row wrap;align-items:center;}
.collectionListpageWrap .topContentWrap .leftPanel img{width:24px;margin:0 10px 0 0;}
.collectionListpageWrap .sidePanel ul li{margin:0 0 25px;}
.collectionListpageWrap .sidePanel ul li p{color:#606060;font-size:1.2rem;margin:0;line-height:20px;}
.collectionListpageWrap .topContentWrap .leftPanel h2 span{font-weight:100;}
.collectionListpageWrap .topContentWrap .leftPanel h2{position:relative;}
.collectionListpageWrap .topContentWrap .leftPanel h2:after{content:"\f11a";font-family:Flaticon;color:#A2A2A2;font-size:0.9rem;padding-left:8px;}
.collectionListpageWrap .sidePanel{border:1px solid #EDEDEF;padding:20px 15px;min-height:70vh;}
.collectionListpageWrap .sidePanel ul li h3{color:#606060;font-size:16px;margin:0;}
.collectionListpageWrap .sidePanel ul li .userlisttWrap{display:flex;flex-flow:row wrap;align-items:center;justify-content:flex-start;}
.collectionListpageWrap .sidePanel ul li .usersListing{justify-content:flex-start;padding-left:10px;margin:0;}
.collectionListpageWrap .sidePanel ul li .usersListing li{margin:0 0 0 -10px;}
.collectionListpageWrap .sidePanel ul li .ctasWrap{padding:0 0 0 10px;}
.collectionListpageWrap .sidePanel ul li .ctasWrap .btn{padding:0;border:0;outline:0;box-shadow:none;background-color:transparent;margin:0 2px;}
.collectionListpageWrap .sidePanel ul li .ctasWrap .btn img{width:22px;}
.collectionListpageWrap .sidePanel ul li .ctasWrap .btn img.img-fluid.imgUser{width:18px;}


.collection-details-left .topwidgetWrap .ctaWrap {
    width: 170px !important;
}

.collection-details-left #artefacts .topwidgetWrap .searchbarWrap {
    width: calc(100% - 170px) !important;
}

.collection-details-left #artefacts .topwidgetWrap .ctaWrap .btn {
    width: 100% !important;
}

#addcollectionartefact .modal-header {
    padding: 10px 10px 10px 25px;
}
.search-box-gray .autocomplete-container .input-container input {
    background: #FAFAFA;
    border-radius: 8px;
}

.autocomplete-container{
    box-shadow: none !important;
}
.addArtefactsPopup .modal-dialog,
.generatereportPopup .modal-dialog,
.assistantPopup .modal-dialog,
.lookupConstituentPopup .modal-dialog,
.addConstituentPopup .modal-dialog{max-width:920px;}
.addArtefactsPopup .modal-header .btnImportant,
.dtlArtefactsPopup .ctaWrap .btn.btnReject,
.memberPopup .ctaWrap .btnRegister{color:#5DB8B2;box-shadow:none;background-color:transparent!important;outline:0;border:1px solid #5DB8B2;border-radius:4px;margin:0 10px 0 0;}
.uploadfileWrap .inputDnD{border:1px dashed #5DB8B2;border-radius:8px;display:flex;flex-flow:column wrap;align-items:center;justify-content:center;position:relative;margin:0;}
.uploadfileWrap .inputDnD h3{position:absolute;top:40%;transform:translateY(-50%);color:#606060;font-size:1.6rem;font-weight:500;text-align:center;}
.uploadfileWrap .inputDnD .form-control-file{position:relative;width:100%;height:100%;min-height:240px;outline:none;visibility:hidden;cursor:pointer;}
.uploadfileWrap .inputDnD .form-control-file:before{content:attr(data-title);position:absolute;left:0;width:100%;min-height:240px;opacity:1;visibility:visible;text-align:center;transition:all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);overflow:visible;    line-height:240px;color:#606060;font-weight:400;}
.addArtefactsPopup ul.uplimagesListing{display:flex;flex-flow:row wrap;align-items:center;padding:10px 0;}
.addArtefactsPopup ul.uplimagesListing li{height:48px;width:42px;border:1px dashed #E2E2E2;border-radius:8px;overflow:hidden;    margin:0 5px 0 0;position:relative;}
.addArtefactsPopup ul.uplimagesListing li img{width:100%;height:100%;object-fit:cover;}
.addArtefactsPopup ul.uplimagesListing li .btn{padding:0;border:0;outline:0;background-color:#ffffff;box-shadow:none;width:12px;height:12px;border-radius:100%;position:absolute;top:2px;right:2px;}
.addArtefactsPopup ul.uplimagesListing li .btn img{width:10px;height:10px;object-fit:cover;position:absolute;top:1px;left:1px;}
.addArtefactsPopup .uploadFile{display:flex;flex-flow:row wrap;justify-content:space-between;}
.addArtefactsPopup .uploadFile label{color:#606060;font-size:1.2rem;}
.addArtefactsPopup .uploadFile .uploadData{width:calc(100% - 95px);}
.addArtefactsPopup .uploadFile .uploadData .upload-btn-wrapper{width:100%;padding:0px 4px 4px;}
.addArtefactsPopup .uploadFile .uploadData p{color:#A7A7A7;font-size:1rem;margin:-7px 0 0;line-height:13px;font-weight:300;}
.addArtefactsPopup .imginfoWrap{padding:15px 0 0;}
.addArtefactsPopup .imginfoWrap h2{color:#606060;font-size:1.5rem;}
.addArtefactsPopup .imginfoWrap table thead tr{background-color:#FAFAFA;}
.addArtefactsPopup .imginfoWrap table tr th,
.addArtefactsPopup .imginfoWrap table tr td{padding:3px 3px;font-size:1rem;}
.addArtefactsPopup .imginfoWrap table tr td [type="radio"]:checked + label,
.addArtefactsPopup .imginfoWrap table tr td [type="radio"]:not(:checked) + label{margin:0 0 10px;}

.dtlArtefactsPopup{padding:0!important;}
.dtlArtefactsPopup .modal-dialog{max-width:100%;margin:256px 0;}
.dtlArtefactsPopup .modal-header{border:0;}
.dtlArtefactsPopup .modal-header .close{position:absolute;top:26px;right:15px;padding:0;text-shadow:none;color:#ffffff;
opacity:1;font-size:2.6rem;}
.dtlArtefactsPopup .modal-header .close:hover{color:#ffffff;opacity:1;}
.dtlArtefactsPopup .titleWrap{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;}
.dtlArtefactsPopup .titleWrap h2{color:#4E4E4E;font-size:1.6rem;}
.dtlArtefactsPopup .ctaWrap .btn{background-color:#5DB8B2;color:#ffffff;border:0;box-shadow:none;padding:6px 15px;}
.dtlArtefactsPopup .metaData{padding:20px 0;}
.dtlArtefactsPopup .metaData ul{display:flex;flex-flow:row wrap;align-items:center;}
.dtlArtefactsPopup .metaData ul li{display:flex;flex-flow:row wrap;align-items:center;margin:0 20px 0 0;}
.dtlArtefactsPopup .metaData ul li .icoWrap img{width:22px;margin:0 8px 0 0;}
.dtlArtefactsPopup .metaData ul li .icoWrap img.imgUser{width:14px;}
.dtlArtefactsPopup .metaData ul li .dataWrap p{color:#707070;margin:0;}
.dtlArtefactsPopup .bigImgWrap{width:100%;padding:0;margin:0 auto;}
.dtlArtefactsPopup .bigImgWrap .imgWrap{border-radius:8px;overflow:hidden;}
.dtlArtefactsPopup .bigImgWrap .imgWrap img{width:100%; max-height:300px;object-fit:cover;}
.dtlArtefactsPopup .bigImgWrap ul.thumbnails{display:flex;flex-flow:row wrap;justify-content:flex-start;align-items:center;padding:15px 0 0;}
.dtlArtefactsPopup .bigImgWrap ul.thumbnails li{height:70px;width:70px;border-radius:8px;overflow:hidden;margin:0 10px 0 0;}
.dtlArtefactsPopup .bigImgWrap ul.thumbnails li:last-child{margin:0;}
.dtlArtefactsPopup .bigImgWrap ul.thumbnails li img{width:100%;height:100%;object-fit:cover;}
.dtlArtefactsPopup .modal-header button.btn {
    margin-left: 10px;
}


.generatereportPopup .inputField{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;}
.generatereportPopup .inputField .imgWrap{background-color:#F9F9F9;width:30px;padding:0 6px;height:100%;border-radius:4px;}
.generatereportPopup form ul li .formField label{width:110px;}
.generatereportPopup .inputField,
.generatereportPopup form ul li .formField input,
.generatereportPopup form ul li .selectWtap{width:calc(100% - 120px)}
.generatereportPopup .inputField{border:1px solid #EDEDEF;border-radius:4px;}
.generatereportPopup .inputField input{width:100%;border:0;}
.generatereportPopup .sectionTitle h2{color:#24243A;margin:0;background-color:#F9F9F9;font-size:1.6rem;padding:12px 20px;}
.generatereportPopup table tr td a.colorGreen{color:#5DB8B2;}

.memberPopup .ctaWrap .btnRegister img{width:16px;margin:0 6px 0 0;}
.memberPopup .searchWrap{width:100%;margin:0 0 30px;}
.memberPopup .searchWrap img{width:16px;}
.memberPopup .searchWrap input::-webkit-input-placeholder{color:#BCBCBC;}
.memberPopup .searchWrap input::-moz-placeholder{color:#BCBCBC;}
.memberPopup .searchWrap input:-ms-input-placeholder{color:#BCBCBC;}
.memberPopup .searchWrap input:-moz-placeholder{color:#BCBCBC;}
.memberPopup table tr td .btnMinus{background-color:#f3f3f3;width:28px;height:28px;border-radius:100%;padding:0;outline:0;box-shadow:none;border:0;margin:0 10px;}
.memberPopup table tr td .btnMinus span{color:#5DB8B2;font-size:2.2rem;line-height:13px;}
.memberPopup table tr td form ul li{margin:0;}
.memberPopup table tr td form ul li .selectWtap{width:100%;height:38px;}
.memberPopup table tr td form ul li .selectWtap select{height:100%;}
.memberPopup .modal-dialog .modal-content{max-height:90vh;}

.assistantPopup ul.formList,
.addConstituentPopup ul.formList{border-top:1px solid #EBEBEC;padding:30px 0 0;display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;}
.assistantPopup ul.formList li,
.addConstituentPopup ul.formList li{width:48%;display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;}
.assistantPopup ul.formList li label,
.addConstituentPopup ul.formList label{width:140px;}
.assistantPopup ul.formList li input,
.assistantPopup ul.formList li .selectWtap,
.addConstituentPopup ul.formList input,
.addConstituentPopup ul.formList .selectWtap{width:calc(100% - 150px);}
.assistantPopup .ctaAdd,
.addConstituentPopup .ctaWrap{width:100%;padding:0 0 15px;text-align:right;}
.assistantPopup .modal-dialog,
.addConstituentPopup .modal-dialog{margin:10px auto;}


.lookupConstituentPopup .modal-header{padding:30px 15px 20px;position:relative;}
.lookupConstituentPopup .modal-header p,
.addConstituentPopup .modal-header p{margin:0;color:#707070;padding:0 0 0 34px;}
.lookupConstituentPopup .modal-header .btnClose,
.addConstituentPopup .modal-header .btnClose{position:absolute;top:10px;right:10px;}
.lookupConstituentPopup form ul{display:flex;flex-flow:row wrap;justify-content:space-between;}
.lookupConstituentPopup form ul li{width:100%;}
.lookupConstituentPopup form ul li.half{width:48%;}
.lookupConstituentPopup form ul li label{width:100px}
.lookupConstituentPopup form ul li input{width:calc(100% - 100px);}
.lookupConstituentPopup form ul li .keyfield{border:1px solid #EDEDEF;border-radius:4px;padding:0px 15px 10px;}
.lookupConstituentPopup form ul li .keyfield h3{color:#707070;font-size:1.6rem;margin:0;display:inline-block;position:relative;top:-15px;background-color:#ffffff;padding:0 5px;left:0px;}
.lookupConstituentPopup form ul li .radioWrap label{width:100%;}
.lookupConstituentPopup form ul li .inputWrap input{width:100%;border:1px solid #EDEDEF;border-radius:4px;}
.lookupConstituentPopup .suggestionsWrap h2{color:#707070;font-size:1.6rem;margin:0 0 10px;}
.lookupConstituentPopup .suggestionsWrap ul{border:1px solid #EDEDEF;border-radius:4px;padding:15px;}
.lookupConstituentPopup .suggestionsWrap ul li{color:#707070;}
.lookupConstituentPopup .btnsWrap{width:100%;display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;}

.addConstituentPopup .modal-header h5 span{color:#A7A7A7;font-size:2.2rem;font-weight:normal;margin:0 10px 0 0;}
.addConstituentPopup .modal-header p{padding:0 0 0 24px;}
.addConstituentPopup ul.formList{border:0;padding:0;}


.headerNewWrap .navbar{padding:6px 30px;height: 55px;}
.headerNewWrap .navbar .nav li a.dropdown-toggle p{font-weight:500;}
.headerNewWrap .navbar .nav li a.dropdown-toggle p span{color:#512378;font-weight:400;}
.pageWrappper{width:100%;margin:0 auto;padding:0;display:flex;flex-flow:row wrap;justify-content:space-between;background-color:#F2F5FA;}
.pageWrappper .sidebarWrap{width:270px;background-color:#ffffff;    height: calc(100vh - 55px);    position: relative;}
.pageWrappper .sidebarWrap ul{padding:20px;}
.pageWrappper .sidebarWrap ul li{width:100%;display:flex;flex-flow:row wrap;align-items:center;justify-content:flex-start;border-radius:8px;-webkit-border-radius:8px;padding:6px 15px 8px;margin:0 0 10px;    cursor: pointer;}
.pageWrappper .sidebarWrap ul li:hover,
.pageWrappper .sidebarWrap ul li.active{background-color:#512378}
.pageWrappper .sidebarWrap ul li.divider{border-bottom:1px solid #e6e6e6;padding:0;border-radius:0;-webkit-border-radius:0;margin:15px 0;}
.pageWrappper .sidebarWrap ul li.divider:hover{background-color:transparent;}
.pageWrappper .sidebarWrap ul li a{width:100%;display:flex;flex-flow:row wrap;align-items:center;justify-content:flex-start;}
.pageWrappper .sidebarWrap ul li .icoWrap{width:16px;margin:0 10px 0 0;}
.pageWrappper .sidebarWrap ul li .icoWrap .noactivenavTab{display:inline-block;}
.pageWrappper .sidebarWrap ul li .icoWrap .activenavTab{display:none;}
.pageWrappper .sidebarWrap ul li:hover .icoWrap .noactivenavTab,
.pageWrappper .sidebarWrap ul li.active .icoWrap .noactivenavTab{display:none;}
.pageWrappper .sidebarWrap ul li .icoWrap .activenavTab{display:none;}
.pageWrappper .sidebarWrap ul li:hover .icoWrap .activenavTab,
.pageWrappper .sidebarWrap ul li.active .icoWrap .activenavTab{display:inline-block;}
.pageWrappper .sidebarWrap ul li .dataWrap{width:calc(100% - 30px);}
.pageWrappper .sidebarWrap ul li .dataWrap p{margin:0;color:#AEAEAE;text-transform:capitalize;}
.pageWrappper .sidebarWrap ul li:hover .dataWrap p,
.pageWrappper .sidebarWrap ul li.active .dataWrap p{color:#ffffff;}
.pageWrappper .sidebarWrap .gopremiumWrap{box-shadow: none;
    background-color: #F2F5FA;
    left: 20px;
    right: auto;
    margin: 0 auto;
    bottom: 20px;
    position: absolute;}
.pageWrappper .sidebarWrap .gopremiumWrap a.btnlearnMore{padding:2px 10px;}
.maincontentWrap {
    width: calc(100% - 270px);
    padding: 20px 40px;
    height: calc(100vh - 55px);
    overflow: auto;
}

.pageTitle{width:100%;margin:0 auto;text-align:left;}
.pageTitle h2{color:#4E4E4E;font-size:2rem;margin:0;}
.topwidgetWrap{width:100%;display:flex;flex-flow:row wrap;align-items:center;justify-content:center;padding:15px 0;}
.topwidgetWrap .searchbarWrap{width:100%;margin:0 auto;display:flex;flex-flow:row wrap;align-items:center;justify-content:space-between;}
.topwidgetWrap .searchbarWrap .inputField{background-color:#ffffff;width:calc(100% - 100px);border-radius:5px 0 0 5px;-webkit-border-radius:5px 0 0 5px;padding:2px 10px 2px 40px;height:40px;position:relative;}
.topwidgetWrap .searchbarWrap .inputField input{height:100%;width:100%;border:0;padding:10px 0px;outline:0;border-radius:5px 0 0 5px;-webkit-border-radius:5px 0 0 5px;box-shadow:none;}
.topwidgetWrap .searchbarWrap .inputField input::-webkit-input-placeholder{color:#BCBCBC;font-size:1.3rem;}
.topwidgetWrap .searchbarWrap .inputField input::-moz-placeholder{color:#BCBCBC;font-size:1.3rem;}
.topwidgetWrap .searchbarWrap .inputField input:-ms-input-placeholder{color:#BCBCBC;font-size:1.3rem;}
.topwidgetWrap .searchbarWrap .inputField input:-moz-placeholder{color:#BCBCBC;font-size:1.3rem;}
.topwidgetWrap .searchbarWrap .inputField:before{content:"\f130";font-family:Flaticon;position:absolute;left:15px;top:50%;transform:translateY(-50%);color:#BCBCBC;}
.topwidgetWrap .searchbarWrap .btn{border-radius:0px 5px 5px 0px;-webkit-border-radius:0px 5px 5px 0px;background-color:#5DB8B2;color:#ffffff;outline:0;box-shadow:none;width:100px;height:40px;font-size:1.4rem;padding:6px;}
.userimformationWrap .boxWrap{padding:20px;background-color:#ffffff;border-radius:5px;-webkit-border-radius:5px;border:1px solid #E0E0E0;margin:0 0 20px;}
.userimformationWrap .boxWrap .titleWrap{display:flex;flex-flow:row wrap;justify-content:center;align-items:center;}
.userimformationWrap .boxWrap .titleWrap .imgWrap{width:20px;}
.userimformationWrap .boxWrap .titleWrap .dataWrap{text-align:left;padding:0 0 0 10px;}
.userimformationWrap .boxWrap .titleWrap .dataWrap p{color:#606060;margin:0;font-size: 16px;
    font-weight: 500;}
.userimformationWrap .boxWrap .countWrap{width:100%;text-align:center;padding:30px 0 20px;}
.userimformationWrap .boxWrap .countWrap h2{color:#606060;margin:0 auto;font-weight: 500;font-size: 4rem;}
.userimformationWrap .userProfileBox{display:flex;flex-flow:row wrap;align-items:center;justify-content:space-between;}
.userimformationWrap .userProfileBox .imgWrap{width:40px;height:40px;border-radius:100%;overflow:hidden;}
.userimformationWrap .userProfileBox .imgWrap img{width:100%;height:100%;object-fit:cover;}
.userimformationWrap .userProfileBox .usernameWrap{width:calc(100% - 40px);padding:0 0 0 20px;}
.userimformationWrap .userProfileBox .usernameWrap h2{color:#606060;text-transform:capitalize;margin:0;font-size:2rem;}
.userimformationWrap .userinfoBox .middledataWrap{padding:25px 0 15px;display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;}
.userimformationWrap .userinfoBox .middledataWrap p,
.userimformationWrap .subscriptionplanWrap ul li p{color:#606060;margin:0;}
.userimformationWrap .userinfoBox .middledataWrap h2,
.userimformationWrap .subscriptionplanWrap .titleWrap h2,
.userimformationWrap .subscriptionplanWrap ul li h2{color:#606060;margin:0;font-size:1.6rem;}
.userimformationWrap .userinfoBox .middledataWrap .roleWrap{width:40%;background-color:#D33E6D15;padding:20px 20px;border-radius:5px;}
.userimformationWrap .userinfoBox .middledataWrap .memberWrap{width:57%;background-color:#FFB92B1A;padding:20px 20px;border-radius:5px;}
.userimformationWrap .subscriptionplanWrap{width:100%;background-color:#5DB8B233;padding:20px 20px;border-radius:5px;}
.userimformationWrap .subscriptionplanWrap .titleWrap{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;}
.userimformationWrap .subscriptionplanWrap .titleWrap .btn{border-radius:5px;-webkit-border-radius:5px;background-color:#5DB8B2;color:#ffffff;outline:0;box-shadow:none;font-size:1.4rem;padding:6px 16px;outline:0;box-shadow:none;}
.userimformationWrap .subscriptionplanWrap ul{display:flex;flex-flow:row wrap;justify-content:space-between;width:100%;padding:25px 0 0;}
.userimformationWrap .subscriptionplanWrap ul li{width:33%;text-align:left;margin:0 0 20px;}

.topwidgetWrap{justify-content:space-between;padding:15px 0 0;}
.topwidgetWrap .ctaWrap{display:flex;flex-flow:row wrap;justify-content:space-between;}
.topwidgetWrap .ctaWrap .btn{border-radius:5px;-webkit-border-radius:5px;background-color:#5DB8B2;color:#ffffff;outline:0;box-shadow:none;font-size:1.4rem;padding:6px 17px;border:0;height:40px;}
.topwidgetWrap .ctaWrap .btn img{width:18px;margin:0 6px 0 0;}
.filterWrap{width:100%;margin:0 auto;padding:0;}
.filterWrap .filterTop{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;padding:5px 0;}
.filterWrap .filterTop h3{color:#838383;font-size:1.4rem;margin:0;}
.filterWrap .filterTop .btn{padding:0;border:0;outline:0;box-shadow:none;border-radius:0;-webkit-border-radius:0;background-color:transparent;color:#5DB8B2;font-weight:500;font-size:1.4rem;}
.filterWrap .filterinputFields{width:100%;margin:0 auto;padding:0;}
.filterWrap .filterinputFields ul.formList{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;}
.filterWrap .filterinputFields ul.formList li{width:19.33%;}
.filterWrap .filterinputFields ul.formList li .selectWrap{width:100%;border-radius:5px;-webkit-border-radius:5px;background-color:#ffffff;height:36px;border:1px solid #EDEDEF;position:relative;font-family: 'Geometria';}
.filterWrap .filterinputFields ul.formList li .selectWrap:after{content:"\f11a";font-family:Flaticon;color:#A2A2A2;font-size:0.9rem;position:absolute;right:8px;top:50%;transform:translateY(-50%);pointer-events: none;}
.filterWrap .filterinputFields ul.formList li .selectWrap select{border:0;outline:0;box-shadow:none;border-radius:5px;-webkit-border-radius:5px;height:100%;width:100%;appearance:none;-webkit-appearance:none;color:#606060;padding:2px 0px 2px 6px;font-size:1.3rem;font-family: 'Geometria';}
.filterWrap .sortingWrap{width:100%;margin:0 auto;display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;background-color:#ffffff;padding:6px 8px 6px 12px;}
.filterWrap .sortingWrap .pageResult p{margin:0;text-align:left;color:#000000;font-size:1.2rem;}
.filterWrap .sortingFileds ul{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;}
.filterWrap .sortingFileds ul li{display:flex;flex-flow:row wrap;align-items:center;padding:0 3px;padding-left: 15px;}
.filterWrap .sortingFileds ul li:last-child{display:flex;flex-flow:row wrap;align-items:center;padding:0 3px;padding-left: 5px;}
.filterWrap .sortingFileds ul li label{color:#000000;font-size:1.2rem;margin:0 4px 0 0;padding:0;}
.filterWrap .sortingFileds ul li .selectWrap{background-color:#F8F8F8;border:1px solid #EDEDEF;border-radius:5px;-webkit-border-radius:5px;position:relative;}
.filterWrap .sortingFileds ul li .selectWrap:before,
.filterWrap .sortingFileds ul li .selectWrap:after{content:"\f11a";font-family:Flaticon;color:#A2A2A2;font-size:0.8rem;position:absolute;right:5px;top:4px; pointer-events:none;}
.filterWrap .sortingFileds ul li .selectWrap:before{top:-2px;transform:rotate(180deg);}
.filterWrap .sortingFileds ul li .selectWrap select{border:0;outline:0;box-shadow:none;border-radius:5px;-webkit-border-radius:5px;height:100%;width:100%;appearance:none;-webkit-appearance:none;color:#606060;font-size:1.2rem;background-color:transparent;padding:0px 16px 0px 6px;}
.filterWrap .sortingFileds ul li a{background-color:#F8F8F8;color:#838383;border-radius:5px;-webkit-border-radius:5px;font-size:1.1rem;padding:0px 8px;}
.filterWrap .sortingFileds ul li:hover a,
.filterWrap .sortingFileds ul li a.active{background-color:#5DB8B2;color:#ffffff;}

.artefactsItemWrap{width:100%;margin:0 auto;padding:15px 10px;}
.artefactsItemWrap .itemBox{background-color:#ffffff;margin:0 auto 20px;border-radius:13px;}
.artefactsItemWrap .itemBox .imgWrap{
    border-radius:13px 13px 0 0;overflow:hidden;
    /* height:180px; */
}
.artefactsItemWrap .itemBox .dataWrap{padding:12px;}
.artefactsItemWrap .itemBox .dataWrap .titleWrap{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;}
.artefactsItemWrap .itemBox .dataWrap .titleWrap h2{color:#606060;font-size:1.6rem;margin:0;}
.artefactsItemWrap .itemBox .dataWrap .titleWrap .btn{padding:0;border:0;outline:0;box-shadow:none;border-radius:0;-webkit-border-radius:0;background-color:transparent;}
.artefactsItemWrap .itemBox .dataWrap .titleWrap .btn img{width:24px;}
.artefactsItemWrap .itemBox .dataWrap ul{padding:15px 0 0;}
.artefactsItemWrap .itemBox .dataWrap ul li{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;margin:0 auto 4px;}
.artefactsItemWrap .itemBox .dataWrap ul li .iconWrap{width:20px;}
.artefactsItemWrap .itemBox .dataWrap ul li .iconWrap img{width:12px;}
.artefactsItemWrap .itemBox .dataWrap ul li .iconWrap h3{margin:0;color:#AEAEAE;font-size:1.6rem;}
.artefactsItemWrap .itemBox .dataWrap ul li .txtWrap{width:calc(100% - 20px);}
.artefactsItemWrap .itemBox .dataWrap ul li .txtWrap p{margin:0;color:#707070;font-size:1.3rem;}
.artefactsItemWrap .itemBox .imgWrap img.img-fluid {
    /* max-width: 100%; */
}

.pricingtableWrap{width:100%;margin:0 auto;}
.pricingtableWrap .pricingBox{border:1px solid #EDEDEF;border-radius:8px;box-shadow:0px 3px 10px #00000012;background-color:#ffffff;text-align:left;padding:35px 25px 25px;}
.pricingtableWrap .pricingBox.priceFree{border-top:6px solid #ED164E;}
.pricingtableWrap .pricingBox.pricePremium{border-top:6px solid #5DB8B2;}
.pricingtableWrap .pricingBox.pricePremiumPlus{border-top:6px solid #512378;}
.pricingtableWrap .pricingBox .titleWrap p{color:#585858;margin:0 0 2px;font-weight:500;}
.pricingtableWrap .pricingBox .titleWrap h2{color:#585858;font-weight:400;font-size:1.6rem;}
.pricingtableWrap .pricingBox .titleWrap h2 span{font-weight:bold;font-size:2.8rem;}
.pricingtableWrap .pricingBox .titleWrap h2 span .rs-icon {
    font-family: cursive;
}
.pricingtableWrap .pricingBox .dataWrap{padding:20px 0;}
.pricingtableWrap .pricingBox .dataWrap p{margin:0;color:#606060;line-height:20px;}
.pricingtableWrap .pricingBox ul li{position:relative;padding:0 0 0 22px;margin:0 0 3px;}
.pricingtableWrap .pricingBox ul li:before{content:"\f139";font-family:Flaticon;color:#5DB8B2;font-size:1.1rem;position:absolute;left:0;top:2px;}
.pricingtableWrap .pricingBox .ctaWrap{padding:25px 0 0;text-align:center;}
.pricingtableWrap .pricingBox .ctaWrap .btn{outline:0;padding:8px 50px;color:#ffffff;box-shadow:none;border:0;border-radius:5px;font-size:1.3rem;}
.pricingtableWrap .pricingBox.priceFree .ctaWrap .btn{background-color:#ED164E;}
.pricingtableWrap .pricingBox.pricePremium .ctaWrap .btn{background-color:#5DB8B2;}
.pricingtableWrap .pricingBox.pricePremiumPlus .ctaWrap .btn{background-color:#512378;}


.atist-box {
    background: #fff;
    color: #606060;
    border-radius: 10px;
    margin: 20px 0px;
}

.artist-img-box {
    width: 15%;
    display: inline-block;
    float: left;
}
.artist-info-box {
    width: 85%;
    display: inline-block;
    padding: 15px 25px;
    /* min-height: 200px;
    max-height: 200px; */
}
.artist-name {
    text-transform: capitalize;
    font-size: 22px;
}
.artist-img-box img {
    /* width: 100%; */
    /* border-top-left-radius: 10px;
    border-bottom-left-radius: 10px; */
}

.artist-detail-list-box {
    width: 25%;
    display: inline-block;
}
.artist-detail-list-box p {
    font-weight: 500;
    display: block;
    line-height: 1;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    color: #4E4E4E;
    font-size: 16px;
    padding-top: 2px;
}

.signupsubscription .pricingtableWrap {
    padding: 50px 0px;
}

.signupsubscription .pricingtableWrap label {
    font-size: 18px;
    color: #727272;
    font-weight: 500;
    padding: 30px 0px;
}

.setting-maintabs {
    background: #fff;
}

.setting-maintabs .nav-tabs {
    border-bottom: 0px solid #dee2e6;
}

.setting-maintabs .nav-tabs .nav-item{
    width: 20%;
    text-align: center;

}

.setting-maintabs .nav-tabs .nav-item .nav-link{
    color: #606060;
    padding: 5px 20px;
    border-radius: 0px;
    border: 0px !important;
}

.setting-maintabs .nav-tabs .nav-item.show .nav-link,
.setting-maintabs .nav-tabs .nav-link.active,
.setting-maintabs .nav-tabs .nav-link:focus,
.setting-maintabs .nav-tabs .nav-link:hover {
    outline: 0;
    color: #5DB8B2;
    border-bottom: 4px solid #5DB8B2 !important;
    background: #F8FCFC;
}

.setting-maintabs .mainContent.managePageWrap {
    padding: 30px;
}

.setting-maintabs #profilesettings {
    width: 70%;
}

.setting-maintabs li.editImage {
    min-height: 75px;
    display: flex;
    margin-bottom: 30px;
    flex-flow: row wrap;
    align-items: center;
    justify-content:flex-start;
}

.setting-maintabs .detail-section {
    margin-bottom: 30px;
}

.setting-maintabs .managePageWrap .pageTitle {
    padding: 0px;
    margin-bottom: 15px;
    text-align: right;
    display: block;
}

.setting-maintabs .managePageWrap .pageTitle .ctaWrap{
    text-align: right;
}

.managePageWrap .leftbarWrap ul li ul li.active-tab {
    background: #F7FCFB;
    border: 1px solid #f7FCFB;
}

.managePageWrap .leftbarWrap ul li ul li.active-tab a{
    color: #5DB8B2;
}

.autocomplete-container {
    z-index: 1;
}

/* custom radio start */
.radiolabel{
    color:#313131;
}
.custom-radio-block {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 14px;
    margin-right: 15px;
    color: #313131;
  }

  /* Hide the browser's default radio button */
  .custom-radio-block input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 5px;
    left: 5px;
    height: 15px;
    width: 15px;
    background-color: #eee;
    border-radius: 50%;
    border: 1px solid #000;
}

  /* On mouse-over, add a grey background color */
  .custom-radio-block:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the radio button is checked, add a blue background */
   .custom-radio-block input:checked ~ .checkmark {
    background-color: #fff;
}

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .custom-radio-block input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .custom-radio-block .checkmark:after {
    top: 2px;
    left: 2px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #000;
}

  /* Custom radio end */


.form-control:focus {
    box-shadow: none;
}

.btnSign button{color:#FFFFFF;    cursor: pointer;background-color:#512378;border-radius:8px;width:100%;display:inline-block;padding: 10px;
    font-size: 1.5rem;}

.page-item.active .page-link {
    background-color: #5DB8B2;
    border-color: #5DB8B2;
}

.upload-btn-wrapper input[type=date] {
    width: 100%;
    border: 0px;
}

.inputbox {
    width: calc(100% - 200px);
    float: right;
}

form ul li .formField .inputbox input,.inputbox input {
    width: 100%;
    height: 31px;
}

span.alert.alert-danger {
    background: transparent;
    border: 0px;
    margin: 0px;
    color: red;
    padding: 0px;
}
button.btn.btnMinus {
    width: 25px;
    height: 25px;
}
.pagi{width:100%;text-align:center;margin:0 auto;float:none;}
.ngx-pagination{margin:0 auto;padding:10px 0 0;}
.ngx-pagination .current{
    background: #5DB8B2 !important;
    border-radius: 4px !important;
    padding: 0px 11px 0px 11px !important;
}

.form-control{
    font-size: 15px !important;
}

.form-control:focus{
    box-shadow: none !important;
}

.pagemodal .modal-content {
    background: transparent;
    border: 0px;
}

.icoback {
    width: 25px;
    border-radius: 20px;
    margin-right: 20px;
    cursor: pointer;
}
.pagemodal .modal-body {
    background: #fff;
    border-radius: 10px;
    padding: 30px;
}

.pagemodal .modal-body form {
    padding: 0 30px 0px 0px;
}

button.btn-primary.btn-right {
    float: right;
}



/* .addArtistpage form ul li .formField {
    width: 45%;
    margin: 5px 20px;
    float: left;
} */
.addartistFormList{display:flex;flex-flow:row wrap;justify-content:space-between;}
.addartistFormList li{width:49%;}
.roletabs {
    width: calc(100% - 200px);
}

 form ul li .roletabs .checkboxWrap {
    width: 100%;
    margin-left: 0px;
}

.roletabs .tab-content {
    background: #F8F8F8;
    padding: 15px 30px;
}

.rolestab-btn {
    text-align: right;
    padding-top: 20px;
    margin: 0px;
    width: 100%;
}

.border-left-red{
    border-left: 7px solid red !important;
}

#addcollectionartefact table tr td:last-child, #addcollectionartefact table tr th:last-child {
    text-align: right;
}

#addcollectionartefact table tr th:last-child {

    padding-right: 30px;
}

#addcollectionartefact table button {
    width: 75px;
    padding: 8px;
}

.topwidgetWrap .ctaWrap button.btn-gridlist {
    background: #F8F8F8;
    color: #BCBCBC;
}

.topwidgetWrap .ctaWrap button.btn-gridlist.btn.active,
button.btn.active{background-color:#5DB8B2;color:#ffffff;outline:0;border:0;box-shadow:none;}



.related-doc-add {
    background: #FBFBFB;
    padding: 20px;
}

.related-doc-add .formField {
    margin-bottom: 10px;
}

.related-doc-add .formField textarea {
    width: calc(100%);
    border: 1px solid #EDEDEF;
    border-radius: 4px;
    height: 100px;
    padding: 5px 10px;
}

.related-doc-add .selectWtap select {
    width: 100%;
    border: 1px solid #EDEDEF;
    border-radius: 4px;
    padding: 5px;
}

.related-doc-add .upload-btn-wrapper{
    width: 100%;
}

.related-doc-add .upload-btn-wrapper input[type="file"] {
    width: 100%;
}

.addArtefactsPopup  table thead tr, .dtlArtefactsPopup table thead tr{
    background-color: #DEF1F0;
}

.related-doc-add input {
    width: 100%;
    border: 1px solid #EDEDEF;
    border-radius: 4px;
    padding: 2px 10px;
}

.doc-upload {
    margin: 20px 0px 30px;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  #addcollectionartefact .table-responsive {
    max-height: 80vh;
    overflow: auto;
}
/* width */
.table-responsive::-webkit-scrollbar{width:3px;height:3px;}
/* Track */
.table-responsive::-webkit-scrollbar-track{background:#f1f1f1; }
/* Handle */
::-webkit-scrollbar-thumb{background:#888; }
/* Handle on hover */
.table-responsive::-webkit-scrollbar-thumb:hover{background:#555; }


.memberPopup table tr th:last-child, .memberPopup table tr td:last-child {
    width: 25%;
    text-align: center;
}

.contact-number {
    width: calc(100% - 200px);
}

.contact-number .inputField.form-group.countrycode{width:74px;}
.contact-number  .inputField.phoneno{width:calc(100% - 80px);display:inline-block;}

.artistview-detail .topwidgetWrap .ctaWrap {
    width: 180px !important;
}

.artistview-detail .topwidgetWrap .searchbarWrap{
    width: calc(100% - 180px) !important;
}

.artistview-detail .topwidgetWrap .ctaWrap button.btn {
    width: 100% !important;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 20px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.btn-transperent {
    background: transparent;
    padding: 8px 15px;
    color: #5DB8B2;
    border: 1px solid #5DB8B2;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url(../img/clock.svg) no-repeat;
    background-color: #F9F9F9;
    width: 25px;
    height: 25px;
    border-width: thin;
    background-position: center;
}

.upload-btn-wrapper input[type=date] {
    padding: 1px;
    padding-right: 0px;
}

.addartistfields .addArtistpage ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.addartistfields .addArtistpage ul li {
    width: 48%;
}

.addartistfields .addArtistpage ul li .formField{
    width: 100%;
    margin: 0px;
}

.addartistfields .modal-body, .addartistfields .modal-body .col-md-12 {
    padding: 0px;
}

.addartistfields .addArtistpage ul li .formField label{
    width: 160px;
}

.addartistfields .addArtistpage form ul li .formField input,
.addartistfields .addArtistpage form ul li .formField textarea {
    width: calc(100% - 160px);
}

.addartistfields .addArtistpage form ul li .upload-btn-wrapper,.addartistfields .addArtistpage form ul li .selectWtap {
    width: calc(100% - 160px);
}

.addartistfields .addArtistpage form ul li .upload-btn-wrapper input{
    width: 100%;
}

.addartistfields .addArtistpage form > div{
    text-align: right;
}

.addartistfields .addArtistpage form > div button{
    margin: 0 0 0 10px;
}

.radiobox {
    display: flex;
    width: calc(100% - 200px);
    flex-flow: row wrap;
    justify-content: flex-start;
}

.radiobox label.custom-radio-block{
    width: auto;
}

.tab-verscroll {
    height: 55vh;
    overflow: auto;
}

.c-radio {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
}

 .formField .c-radio label {
    width: auto;
}

.pageWrappper .sidebarWrap,
.pageWrappper .maincontentWrap{-webkit-transition:all .25s linear;-moz-transition:all .25s linear;-o-transition:all .25s linear;transition: all .25s linear;}
.pageWrappper.toggled .sidebarWrap{width:0;left:-270px;position:relative}
.pageWrappper.toggled .maincontentWrap{width:calc(100% - 0px);margin:0;}

#menu-toggle{display: none;background-color:#5DB8B2;color:#ffffff;margin:0px 10px;}
.boxes-4{
    /* padding-left: 1rem;
    padding-right: 1rem; */
}

.invalid-feedback {
    line-height: 1.2;
    font-size: 11px;
    font-weight: 500;
}

.roleWrap h2 {
    text-transform: capitalize;
}

#classification .topwidgetWrap .ctaWrap{
    /* width: 200px; */
}

#classification .searchbarWrap {
    width: calc(100% - 200px);
}


.termspopup .modal-content {
    height: 100vh;
    overflow: auto;
}

.form-group.btnSign {
    text-align: center;
}

.btnSign button {
    width: 50%;
}

@media(max-width:1024px){


    /* #menu-toggle {
        display: inline-block;
    } */

    /*  */

    .navbar .nav {
        width: calc(100% - 300px);
    }

    .navbar .nav li {
        margin: 0 0 0 10px;
    }

    .navbar>.container, .navbar>.container-fluid {
        padding: 0px 5px;
    }

    .pageWrappper .sidebarWrap {
        width: 230px;
    }

    .gopremiumWrap {
        max-width: 210px;
    }
    .maincontentWrap {
        width: calc(100% - 230px);
        padding: 20px 20px;
    }
}

@media(max-width:768px){

    .signinWrap form ul li {
        margin: 0 0 0px;
    }

    .headerNewWrap .navbar {
        padding: 6px 5px;
    }

    .navbar-brand.logoWrap1 {
        margin: 0 10px 0 0;
    }

    .navbar-brand.logoWrap1:after {
        right: 0px;
    }

    .boxes-4 {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .navbar .nav li a.dropdown-toggle p {
        font-size: 1rem;
        line-height: 1;
        margin: 0 2px;
    }

    .userimformationWrap .userinfoBox .middledataWrap .roleWrap {
        padding: 20px 10px;
    }
    .userimformationWrap .userinfoBox .middledataWrap h2, .userimformationWrap .subscriptionplanWrap .titleWrap h2, .userimformationWrap .subscriptionplanWrap ul li h2 {
        font-size: 1.3rem;
    }
    .userimformationWrap .subscriptionplanWrap {
        padding: 20px 15px;
     }

     /* .userimformationWrap .subscriptionplanWrap ul li {
        width: 47%;
        margin: 0 0px 20px;
        margin-right: 5px;
    }
     */
    .userimformationWrap .boxWrap .titleWrap .dataWrap p {
        font-size: 14px;
    }

    .userimformationWrap .boxWrap .countWrap {
        width: 100%;
        text-align: center;
        padding: 15px 0px;
    }
}




@media(max-width:1365px){
    .signinWrap{background-size:cover;}
}
@media(max-width:1280px){
    html{font-size:60.5%;}
    .pageWrappper .sidebarWrap{width:250px;}
    .gopremiumWrap{max-width:210px;}
    .maincontentWrap{width:calc(100% - 250px);}
    .userimformationWrap .userinfoBox .middledataWrap h2,
    .userimformationWrap .subscriptionplanWrap .titleWrap h2,
    .userimformationWrap .subscriptionplanWrap ul li h2{font-size:1.4rem;}
    ul.collectionList li{margin:0 0 15px;}
    ul.collectionList li .item-contentwrap{height:100%;margin:0;}
    .managePageWrap .leftbarWrap ul>li>a{font-size:1.4rem;}
}
@media(max-width:1200px){
    .managePageWrap .leftbarWrap ul>li>a{font-size:1.3rem;}
}
@media(max-width:1199px){
    ul.collectionList li .titleWrap .leftData h2{font-size:1.6rem;}
}
@media(max-width:1100px){
    html{font-size:56.5%;}
    .userimformationWrap .subscriptionplanWrap ul li{width:49%;}
    .formField .c-radio label{margin-right:5px;padding-left:26px;}
}
@media(max-width:1024px){
    #menu-toggle{display:inline-block;outline:0;box-shadow:none;margin:0 10px;top:50%;transform:translateY(-50%);}
    #generatereport .w-50{width:80%!important;padding:0;}
}
@media(max-width:1023px){
    .filterWrap .filterinputFields ul.formList li .selectWrap select{padding:2px 20px 2px 6px;}
}
@media(max-width:991px){
    html{font-size:54.5%;}
    .userimformationWrap .boxes-4{padding-left:15px;padding-right:15px;}
    .userimformationWrap .pl-3{padding-left:15px!important;}
    .userimformationWrap .pr-3{padding-right:15px!important;}
    .table-responsive table{border-collapse:collapse;}
    .table-responsive table tr th,
    .table-responsive table tr td{white-space:nowrap;padding:10px 30px;}
    .table-responsive table tr td:last-child{padding:10px 100px 10px 10px;}
    .table-responsive.artistTblWrap table tr td:last-child,
    .table-responsive.classificationTblWrap table tr td:last-child,
    .table-responsive.memberTblWrap table tr td:last-child,
    .table-responsive.roleTblWrap  table tr td:last-child{padding:10px;}
    .table-responsive.logTblWrap table tr td:last-child{padding:10px 30px;}
    .setting-maintabs #profilesettings{width:100%;}
    .setting-maintabs .nav-tabs .nav-item .nav-link{font-size:1.2rem;padding:5px 7px;}
    .setting-maintabs .mainContent.managePageWrap{padding:30px 10px;}
    .companysettingPopup form ul li.editImage .imgWrap{width:150px;}
    form ul li .formField label{width:150px;}
    form ul li .formField input,
    form ul li .formField textarea,
    .passwordfield .inputField.form-group,
    form ul li .selectWtap,
    .roletabs,
    .contact-number,
    .upload-btn-wrapper,
    .radiobox{width:calc(100% - 160px);}
    .prMobile{padding-right:0;}
    form ul li .checkboxWrap input{width:auto;}
    .artifectsSidePanel{margin:15px 0 0;}
}

@media(max-width:767px){
    .roletabs{width:100%;}
    .pl20px{padding:0;}
    .pricingtableWrap .pricingBox{margin:0 auto 15px;}
    #container.drishy #modal.mchild{width:90%;}
    .addartistfields .addArtistpage ul li,
    .assistantPopup ul.formList li,
    .addConstituentPopup ul.formList li,
    .addartistFormList li{width:100%;}
    .addartistfields .addArtistpage form{padding:0;}
    .pagemodal .modal-body form{padding-right:0;}
}
@media(max-width:699px){
    .pageWrappper.toggled .sidebarWrap{left:-100%;position:fixed;}
    .pageWrappper.toggled .maincontentWrap,
    .pageWrappper .maincontentWrap{width:calc(100% - 0px);}
    .pageWrappper .sidebarWrap{position:fixed;z-index:9;}
    .formPopup .modal-dialog{height:100%;width:96%;flex-flow:row wrap;}
}
@media(max-width:575px){
    .signinWrap form ul li.half{width:100%;}
    #generatereport .w-50{width:100%!important;}
}
@media(max-width:500px){
    .topwidgetWrap .row .col-md-12.d-flex{flex-flow:column;}
    .topwidgetWrap .searchbarWrap,
    #artefacts .topwidgetWrap .searchbarWrap{width:100%!important;}
    .topwidgetWrap .ctaWrap{
      width:100%;margin:10px 0 0;
    }
    .filterWrap .sortingFileds ul{justify-content:flex-start;}
    .filterWrap .sortingFileds ul li{margin:0 0 6px;}
}
@media(max-width:459px){
    html{font-size:52.5%;}
    .signinWrap .formContent .formData{padding:25px;}
    .navbar-brand img.imgLogo1{width:85px;}
    .navbar-brand img.imgLogo2{width:46px;}
    .navbar .nav{width:calc(100% - 180px);}
    .setting-maintabs .nav-tabs{justify-content:space-evenly;}
    .setting-maintabs .nav-tabs .nav-item{width:auto;}
    .setting-maintabs li.editImage{justify-content:center;}
    .companysettingPopup form ul li.editImage .imgWrap{width:100%;margin:0 auto 15px;text-align:center;}
    form ul li .formField label{width:100%;}
    form ul li .formField input,
    form ul li .formField textarea,
    .passwordfield .inputField.form-group,
    form ul li .selectWtap,
    .contact-number,
    .radiobox,
    .upload-btn-wrapper,
    .addartistfields .addArtistpage ul li .formField label,
    .addartistfields .addArtistpage form ul li .formField input,
    .addartistfields .addArtistpage form ul li .formField textarea,
    .addartistfields .addArtistpage form ul li .upload-btn-wrapper,
    .addartistfields .addArtistpage form ul li .selectWtap,
    .assistantPopup ul.formList li label,
    .addConstituentPopup ul.formList label,
    .assistantPopup ul.formList li input,
    .assistantPopup ul.formList li .selectWtap,
    .addConstituentPopup ul.formList input,
    .addConstituentPopup ul.formList .selectWtap{width:100%;}
    form ul li .checkboxWrap input{width:auto;}
}
@media(max-width:420px){
    .btnSign button.btn-transperent,
    .btnSign button.btn-send{width:100%;margin:0 auto 15px;}
    .custom-radio-block{margin-right:10px;}
    .userimformationWrap .subscriptionplanWrap .titleWrap .btn{padding:6px;}
    .navbar-brand.logoWrap1:after{right:-4px;}
    .roletabs .nav-tabs{flex-flow:row;}
}
@media(max-width:340px){
    .signinWrap form ul li .styled-checkbox + label,
    .signinWrap form ul li .rightWrap a{font-size:1.3rem;}
    .userimformationWrap .subscriptionplanWrap ul li{width:100%;}
    .userimformationWrap .col-6{width:100%;max-width:100%;flex:0 0 100%;}
    .userimformationWrap .userinfoBox .middledataWrap .roleWrap{width:100%;margin:0 0 15px;}
    .userimformationWrap .userinfoBox .middledataWrap .memberWrap{width:100%;}
}

a:hover {
    color: #512378;
}

/* .eye-icon img {
  display: none;
}

.eye-icon:hover + .eye-icon img {
  display: block;
} */

.inputField img {
  cursor: pointer;
}

.cross-icon {
  opacity: 0.5;
  cursor: pointer;
}

.file-div {
    width: calc(100% - 200px);
}

.artist-img {
    max-height: 50px;
}

.loader-wrapper {
    left: 50%;
    position: absolute;
}
.loader-table-wrapper {
    height: 200px;
}
#loader {
    position: absolute;
    left: 50%;
    top: 60%;
    z-index: 1;
    width: 100px;
    height: 100px;
    margin: -76px 0 0 -76px;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #512378;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

    /* Add animation to "page content" */
.animate-bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s
}

@-webkit-keyframes animatebottom {
    from { bottom:-100px; opacity:0 } 
    to { bottom:0px; opacity:1 }
}

@keyframes animatebottom { 
    from{ bottom:-100px; opacity:0 } 
    to{ bottom:0; opacity:1 }
}

.sk-chase {
    width: 40px;
    height: 40px;
    position: relative;
    animation: sk-chase 2.5s infinite linear both;
  }
  
  .sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; 
    animation: sk-chase-dot 2.0s infinite ease-in-out both; 
  }
  
  .sk-chase-dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: #512378;
    border-radius: 100%;
    animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
  }
  
  .sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
  .sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
  .sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
  .sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
  .sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
  .sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
  .sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
  .sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
  .sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
  .sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
  .sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
  .sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }
  
  @keyframes sk-chase {
    100% { transform: rotate(360deg); } 
  }
  
  @keyframes sk-chase-dot {
    80%, 100% { transform: rotate(360deg); } 
  }
  
  @keyframes sk-chase-dot-before {
    50% {
      transform: scale(0.4); 
    } 100%, 0% {
      transform: scale(1.0); 
    } 
  }

  /* view image modal */

  body.modal-open {
    overflow: hidden !important;
  }
  
  app-view-image {
    position: fixed;
    z-index: 9999;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    visibility: hidden;
  }
  /* .modal-backdrop {
      z-index: 998 !important;
    } */
  app-view-image .modal-backdrop-root {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: -1;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    -webkit-tap-highlight-color: transparent;
  }
  
  app-view-image .modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    outline: 0;
    transform: translate(0, 100px);
  }
  
  /* modal container width */
  app-view-image .modal-wrapper .modal-container {
    position: relative;
    margin: 32px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 16px);
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
      0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #000;
  }
  
  app-view-image .modal-wrapper .modal-container.modal-container-xs {
    max-width: 444px;
  }
  app-view-image .modal-wrapper .modal-container.modal-container-sm {
    max-width: 600px;
  }
  app-view-image .modal-wrapper .modal-container.modal-container-md {
    max-width: 960px;
  }
  app-view-image .modal-wrapper .modal-container.modal-container-lg {
    max-width: 1280px;
  }
  app-view-image .modal-wrapper .modal-container.modal-container-xl {
    max-width: 1920px;
  }
  app-view-image .modal-wrapper .modal-container.modal-container-fullwidth {
    width: calc(100% - 16px);
  }
  app-view-image .modal-wrapper .modal-container.modal-container-fullscreen {
    width: 100%;
    height: 100%;
    margin: 0;
    max-width: 100%;
    max-height: none;
    border-radius: 0;
  }
  
  /* update for aside panel */
  app-view-image.aside-panel > .modal-wrapper {
    justify-content: flex-end;
    margin-left: 0;
    height: 100%;
    transform: translate(110%, 0);
  }
  
  @media screen and (min-width: 992px) {
    app-view-image.aside-panel > .modal-wrapper {
      margin-left: 270px;
      width: calc(100% - 270px);
    }
  
    app-view-image.aside-panel > .modal-wrapper > .modal-container {
      border-radius: 8px 0 0 8px;
    }
  }
  
  /* on open */
  app-view-image.modal-open {
    visibility: visible;
  }
  app-view-image.modal-open .modal-backdrop-root {
    opacity: 1;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  app-view-image.modal-open .modal-wrapper {
    transform: translate(0, 0);
    transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }

.profile-name {
    background-color: #e2e2e2;
    width: 32px;
    height: 32px;
    padding-top: 2px;
    border-radius: 100%;
    border: 1px solid #115586;
    text-align: center;
}

.date-field-icon {
    color: #5DB8B2;
    position: absolute;
    right: 3px;
    top: 3px;
    pointer-events: none;
  }

input[type='text']:focus, input[type='email']:focus, input[type='number']:focus, select:focus, textarea:focus {
    /* border: 1px solid !important; */
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 50%) !important;
}

.eye-icon:focus-within {
        box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 50%) !important;
}

input[type='radio']:focus + .checkmark {
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 50%) !important;
}

.upload-btn-wrapper:focus-within {
    /* border: 1px solid !important; */
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 50%) !important;
}

.btn-primary:hover {
    background-color: #46938e !important;
    color: #fff !important;
}

.topwidgetWrap .searchbarWrap .btn:hover {
    background-color: #46938e !important;
    color: #fff !important;
}

.ctaWrap .btn:hover {
    background-color: #46938e !important;
    color: #fff !important;
}
.sortingFileds .btn:hover {
    background-color: #46938e !important;
    color: #fff !important;
}

.btnWrap .btn:hover {
    background-color: #46938e !important;
    color: #fff !important;
}
  
.upload-btn-wrapper .btn:hover {
    background-color: #46938e !important;
    color: #fff !important;
} 

.signinWrap .form-group {
    padding: 0 !important;
}
.signinWrap .form-group input{
    padding: 2px 8px !important;
    width: 100% !important;
}

.signinWrap .password input {
    width: calc(100% - 26px) !important;
}
.password .form-group:focus-within {
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 50%) !important;
}

.password .form-group input:focus {
    box-shadow: none !important;
}

.signinWrap input[type='checkbox']:focus + .form-check-label::before {
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 50%) !important;
}
.searchbarWrap input:focus {
    box-shadow: none !important;
}

select:focus-visible, textarea:focus-visible {
    outline: none !important;
}
.password img {
    padding-right: 4px;
}
/* end view image modal */
  

