/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";

.pointer {
  cursor: pointer;
}

button:hover {
  border: none !important;
}

.btn {
  border: none !important;
}

.radio-wrapper {
  width: calc(100% - 200px);
  /* display: flex; */
}

.radio-wrapper label {
  width: unset !important;
}
